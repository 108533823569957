export const numberWithCommas = (n?: number | string): string => {
  if (typeof n === "undefined") {
    return "";
  }
  const parts = n.toString().split(".");

  return `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
    parts[1] ? `.${parts[1]}` : ""
  }`;
};

export const prefixSingleWithZero = (value: string) =>
  `${value.length === 1 ? "0" : ""}${value}`;

export const camelCaseToCapitalisedCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, " $1").trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const camelCaseToSentenceCase = (text: string): string => {
  const result = text
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLocaleLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const camelToSnakeCase = (text: string) =>
  text.replace(/[A-Z]/g, (letter: string, index: number) =>
    index === 0 ? letter.toLowerCase() : `_${letter.toLowerCase()}`,
  );

export const calculatePercentRounded = (
  value: number,
  target: number,
): number => {
  const percent = (value / target) * 100;
  if (percent === Number.POSITIVE_INFINITY || Number.isNaN(percent)) {
    return 0;
  }
  return Math.floor(percent < 1 ? Math.ceil(percent) : Math.floor(percent));
};

export const formatMoney = (value: number): string => {
  const parsed = Number.parseFloat(
    (Math.round(value * 100) / 100).toString(),
  ).toFixed(2);
  if (Number.isNaN(parseFloat(parsed)) || Number.isNaN(value)) {
    return "0";
  }
  return parsed;
};

export const formatMoneyWithCommas = (value: number): string => {
  const parsed = Number.parseFloat(
    (Math.round(value * 100) / 100).toString(),
  ).toFixed(2);
  if (Number.isNaN(parseFloat(parsed)) || Number.isNaN(value)) {
    return "0";
  }
  return numberWithCommas(parsed);
};
